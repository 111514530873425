<template>
    <div>
        <div v-show="!loading" class="container" style="padding-bottom:50px;" v-wechat-title="$route.meta.title=detail.recruit_name" :img-set="detail.recruit_cover">
            <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
                <van-swipe-item v-for="(item,index) in detail.recruit_images" :key="index">
                    <van-image class="swiper-img" :src="item" alt="banner" />
                </van-swipe-item>
            </van-swipe>
            <van-cell class="mb10 container-flex">
                <template #title>
                    {{detail.recruit_name}}
                </template>
                <template #default>
                    <div>
                        <a :href="detail.tel">
                            <van-icon color='#00DE93' size='20px' name="phone-o" />
                        </a>
                    </div>
                </template>
            </van-cell>
            <div class="section mb10">
                <van-cell
                    icon="QuitOutline1"
                    icon-prefix="icon"
                    title="招募人数">
                    <template #default>
                        <div>
                            {{detail.reserve_count}}/{{detail.recruit_total}}
                        </div>
                    </template>
                </van-cell>
            </div>
            
            <div class="section mb10">
                <van-cell
                    icon="QuitOutline1"
                    icon-prefix="icon"
                    title="招募价格"
                />
                <van-row class="student-price">
                    <van-col span="10" offset="2">学员价: {{detail.member_price}}</van-col>
                    <van-col span="10" offset="2">非学员价: {{detail.sale_price}}</van-col>
                </van-row>
            </div>

            <div class="section mb10">
                <van-cell
                    icon="QuitOutline1"
                    icon-prefix="icon"
                    title="招募时间"
                />
                <van-row class="student-price">
                    <van-col span="22" offset="2">{{dayjs(detail.recruit_start).format('YYYY-MM-DD HH:mm')}} - {{dayjs(detail.recruit_end).format('YYYY-MM-DD HH:mm')}}</van-col>
                </van-row>
            </div>
            
            <div class="section mb10">
                <van-cell
                    icon="QuitOutline1"
                    icon-prefix="icon"
                    title="学员权益"
                />
                <div class="student-power">
                    <span>{{fee_title}}</span>
                </div>
            </div>

            <div class="section mb10" v-if="html">
                <van-cell
                    icon="QuitOutline1"
                    icon-prefix="icon"
                    title="招募简介"
                />
                <div @click="toPreview($event)" class="ql-editor" v-html="html"></div>
            </div>

            <div v-if='detail.can_buy' @click="toReport" class="report-btn">马上报名</div>
            <div v-if='!detail.can_buy' class="report-btnF">无法购买</div>
            <copyright v-show='!loading' />
        </div>
        <loadings :loading='loading' />
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { Swipe, SwipeItem } from 'vant'
import { wxPay } from '@/common/wx'
import dayjs from 'dayjs'
import Loadings from '@/components/loading'
import Copyright from '@/components/Copyright'
import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'

    export default {
        name:'reportDetail',
        components:{
            Copyright,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
            loadings:Loadings,
        },
        data() {
            return {
                detail:{},
                html:'',
                richImages:[],
                recruit_id:'',
                money:0,
                fee_title:'',
                loading:false,
            }
        },
        created () {
            this.getDetail()
        },
        methods: {
            dayjs,
            getDetail() {
                this.loading = true
                let meet = this.$route.query
                this.recruit_id = meet.recruit_id
                this.$api.recruit_detail({ recruit_id: this.recruit_id })
                    .then(res=>{
                        if(res.data.recruit_description){
                            let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.recruit_description)
                            this.html = arr[0]
                            this.richImages = arr[1]
                        }
                        res.data.tel = 'tel:'+ res.data.recruit_telphone
                        this.detail = res.data
                        this.money = res.data.member_price
                        this.fee_title = res.data.recruit_premium.fee_title
                        this.loading = false
                    })
                    .catch(err=>{})
            },
            toReport(){
                let recruit_id = [this.detail.recruit_id]
                localStorage.setItem('money',this.money * 100)
                if(this.detail.survey_id){
                    this.$router.push({path:'/survey',query:{id:this.detail.survey_id,zhuqian:1,recruit_ids:JSON.stringify(recruit_id),coupon_type:2}})
                }else{
                    // this.paymentRecruit(this.detail.recruit_id)
                    this.$router.push({path:'/paymentCenter',query:{zhuqian:1,recruit_ids:JSON.stringify(recruit_id),coupon_type:2}})
                }
            },
            toPreview(e){
                if (e.target.tagName == 'IMG') {
                    // this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'course_class_summary'}).then(res=>{})
                    WeixinJSBridge.invoke("imagePreview", {
                        "urls":this.richImages,
                        "current": this.richImages[e.target.src.split('random=')[1] - 1]
                    });
                }
            },
            paymentRecruit(recruit_id, couponId = '') {
                this.$toast.loading({
                    mask: true,
                    message: '支付中...',
                    duration: 0
                })
                const params = {
                    recruit_id: recruit_id
                }
                couponId && (params.coupon_id = couponId)
                this.$api.payment_recruit(params)
                    .then(res => {
                    const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
                    wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
                        .then(res => {
                        this.$toast.success('支付成功')
                        setTimeout(() => {
                            this.$router.push({path:'/submitSuccess',query:{zhuqian:1}})
                        }, 1500)
                        })
                        .catch(err => {
                        this.$toast.fail('支付失败')
                        setTimeout(() => {

                        }, 1500)
                        console.error(err)
                        })
                    })
                    .catch(err => {
                    console.error(err)
                    })
            },
        },
    }
</script>

<style lang="less">
    .swiper {
        &-img {
            width: 100%;
            height: 180px;
        }
    }
    .teacher-list{
        background: #fff;
        .teacher-item{
            display: flex;
            align-items: center;
            padding: 10px 0;
            position: relative;
            .teacher-item-img{
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 50%;
                margin-left: 12px;
                margin-right: 15px;
            }
            .teacher-item-icon{
                position: absolute;
                right: 16px;
            }
        }
    }
    .student-price{
        background-color: #fff;
        padding: 10px 0;
    }
    .ql-editor{
      margin-top: 10px;
      img{
        max-width: 100%;
      }
    }
    .student-num{
        background-color: #fff;
        box-sizing: border-box;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &-item{
            display: flex;
            align-items: center;
            flex-direction:column;
            margin-right: 10px;
            &-img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 5px;
            }
        }
        &-count{
            position: absolute;
            right: 10px;
        }
    }
    .student-power{
        background-color: #fff;
        padding: 10px 20px;
        .student-power-img{
            width: 100%;
            height: 140px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
    .report-btn{
        width: 100%;
        height: 50px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .report-btnF{
        width: 100%;
        height: 50px;
        background-color: #ccc;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    .container-flex .van-cell__value{
        flex: inherit;
    }
</style>